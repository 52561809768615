import ReactDOM from 'react-dom/client';
import axios from 'axios';
//
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import { Link,useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

axios.interceptors.request.use(config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  }, error => {
    return Promise.reject(error);
});

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      const { status, data } = error.response;

      if (status === 400) {
        const errorDetails = data;
        if (errorDetails && errorDetails.errors) {
          alert(`Error: ${errorDetails.errors}`);
        } else {
          alert(`${errorDetails}`);
        }
      } else if (status === 401) {
        // 处理 401 错误，重定向到首页
        alert('Unauthorized access. Redirecting to homepage.');
        const navigate = useNavigate();
        navigate('/login', { replace: true });
 
      } else if (status === 500) {
        const errorDetails = data;
        const errorMessage = errorDetails.Message || 'Internal Server Error';
        const innerMessage = errorDetails.InnerMessage ? `\nDetails: ${errorDetails.InnerMessage}` : '';
        alert(`Error: ${errorMessage}${innerMessage}`);
      }
    }

    return Promise.reject(error);
  }
);


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<App />);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
